




































































































































































import { Component, Mixins } from 'vue-property-decorator'
import Methods from '@/components/methods'
import { get } from '@/http'

@Component
export default class Case extends Mixins(Methods) {
	appSoftware: any = []
	wxSoftware: any = []
	webSoftware: any = []
	otherSoftware: any = []

	created(): void {
		this.getList()
	}
	getList(): void {
		get('software/list', {
        }).then((res: any) => {
            this.appSoftware = res.data.appSoftware
			this.wxSoftware = res.data.wxSoftware
			this.webSoftware = res.data.webSoftware
			this.otherSoftware = res.data.otherSoftware
        })
	}
}
